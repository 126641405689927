import type { MetaField } from '$lib/utils/index';

export const parseImageUrls = (
	image_urls?: string | null | undefined | MetaField
): string[] | undefined => {
	if (!image_urls || image_urls === '[]') {
		return undefined;
	}
	if (typeof image_urls === 'string' && image_urls?.startsWith('https://')) {
		return [image_urls];
	}
	if (image_urls && typeof image_urls !== 'undefined' && typeof image_urls !== 'string') {
		return parseImageUrls(image_urls?.value);
	}
	return JSON.parse(image_urls ?? '[]') as Array<string>;
};

export const getImagePriorityBelowFold = (belowTheFold: boolean) => {
	return {
		fetchpriority: belowTheFold ? 'low' : 'high',
		loading: belowTheFold ? 'lazy' : 'eager',
		decoding: belowTheFold ? 'async' : 'sync'
	};
};
