<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let sale: number = 0;
	let className = '';

	$: absoluteSale = Math.abs(sale);

	export { className as class };
</script>

{#if absoluteSale > 0}
	<div
		class={classes(
			'px-2 py-1 bg-special-dog rounded text-white text-p-12 h-[1.625rem] max-w-min',
			className
		)}
	>
		-{absoluteSale}%
	</div>
{/if}
