<script lang="ts">
	import type { Money } from '$lib/types';
	import { formatPrice } from '$lib/utils/product';
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let price: Money;
	export let compareAtPrice: Money | null | undefined = undefined;
	export let shouldWrap: boolean | undefined = undefined;
	export let className: string | undefined = '';

	export { className as class };

	$: priceData = {
		formattedPrice: formatPrice(price),
		formattedCompareAtPrice: formatPrice(compareAtPrice)
	};

	$: formattedPrice = priceData.formattedPrice;
	$: formattedCompareAtPrice = priceData.formattedCompareAtPrice;
</script>

{#if formattedCompareAtPrice && compareAtPrice?.amount !== 0.0 && formattedCompareAtPrice !== formattedPrice}
	<span
		class={classes(
			'flex items-end gap-x-2.5',
			className,
			shouldWrap ? 'flex-col md:flex-row' : 'flex-row'
		)}
	>
		<span
			class={classes('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
			style:font-size="inherit"
			style:font-weight="inherit"
			style:letter-spacing="inherit"
			style:line-height="inherit"
		>
			{formattedPrice}
		</span>
		<span class="font-medium text-dark-tertiary/35">
			<del>{formattedCompareAtPrice}</del>
		</span>
	</span>
{:else}
	<div
		class={classes('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
		style:font-size="inherit"
		style:font-weight="inherit"
		style:letter-spacing="inherit"
		style:line-height="inherit"
	>
		{formattedPrice}
	</div>
{/if}
