type Options = () => void;

export const onVisible = (node, options: Options) => {
	const observer = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				options();
				observer.disconnect();
			}
		});
	});
	observer.observe(node);
	return {
		destroy() {
			observer.disconnect();
		}
	};
};
