<script lang="ts">
	import type { Money, RequiredProduct } from '$lib/types';
	import { formatPrice } from '$lib/utils/product';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import ProductPrice from './ProductPrice.svelte';

	export let priceRange: RequiredProduct['priceRange'];
	export let compareAtPriceRange: RequiredProduct['compareAtPriceRange'] | null | undefined =
		undefined;
	export let shouldWrap: boolean | undefined = undefined;
	export let className: string | undefined = undefined;

	export { className as class };

	$: priceData = {
		formattedLowerPrice: formatPrice(priceRange.minVariantPrice),
		formattedHigherPrice: formatPrice(priceRange.maxVariantPrice),
		formattedLowerCompareAtPrice: formatPrice(compareAtPriceRange?.minVariantPrice),
		formattedHigherCompareAtPrice: formatPrice(compareAtPriceRange?.maxVariantPrice)
	};

	$: formattedLowerPrice = priceData.formattedLowerPrice;
	$: formattedHigherPrice = priceData.formattedHigherPrice;
	$: formattedLowerCompareAtPrice = priceData.formattedLowerCompareAtPrice;
	$: formattedHigherCompareAtPrice = priceData.formattedHigherCompareAtPrice;
	$$restProps;
</script>

<!-- {#snippet normalPrice(price)}
	<h4
		class={classes('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
		style:font-size="inherit"
		style:font-weight="inherit"
		style:letter-spacing="inherit"
		style:line-height="inherit"
	>
		{price}
	</h4>
{/snippet}
-->
{#if formattedLowerPrice === formattedHigherPrice}
	<ProductPrice
		price={priceRange.minVariantPrice}
		compareAtPrice={compareAtPriceRange?.minVariantPrice}
		{shouldWrap}
		class={className}
	/>
{:else if compareAtPriceRange?.minVariantPrice.amount !== 0.0 && compareAtPriceRange?.maxVariantPrice.amount !== 0.0 && formattedLowerPrice !== formattedLowerCompareAtPrice && formattedHigherPrice !== formattedHigherCompareAtPrice}
	<span
		class={classes(
			'flex items-end gap-x-2.5',
			className,
			shouldWrap ? 'flex-col md:flex-row' : 'flex-row'
		)}
	>
		<span
			class={classes('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
			style:font-size="inherit"
			style:font-weight="inherit"
			style:letter-spacing="inherit"
			style:line-height="inherit"
		>
			{`${formattedLowerPrice} - ${formattedHigherPrice}`}
		</span>
		<span class="font-medium text-dark-tertiary/35">
			<del>{formattedLowerCompareAtPrice} - {formattedHigherCompareAtPrice}</del>
		</span>
	</span>
{:else}
	<div
		class={classes('flex flex-col md:flex-row items-end md:gap-x-2.5', className)}
		style:font-size="inherit"
		style:font-weight="inherit"
		style:letter-spacing="inherit"
		style:line-height="inherit"
	>
		{`${formattedLowerPrice} - ${formattedHigherPrice}`}
	</div>
{/if}
