import posthog from 'posthog-js';

type AddToCartPayload = {
	sku: string;
	source: string;
	quantity: number;
	handle: string;
};

export const recordAddToCart = (payload: AddToCartPayload) => {
	posthog.capture('Add To Cart', payload);
};

export const recordStartCheckout = () => {
	posthog.capture('Start Checkout');
};

export const recordCompleteCheckout = () => {
	posthog.capture('Complete Checkout');
};
